<template>
  <div class="main_page">
    <div class="device_pc">
      <div class="swiper1">
        <Swiper
          :modules="modules"
          class="swiper"
          :pagination="{ clickable: true }"
          loop
          :activeInde="swiper2_active"
          autoplay
          @slideChange="onSlideChange"
          ref="mySwiper1"
        >
          <SwiperSlide v-for="item in carousel" :key="item.id"><img :src="baseUrl + item.images" @click="handleSwiperClick(item)" alt=""></SwiperSlide>
          <!-- <SwiperSlide><img src="https://ooo.0x0.ooo/2024/09/21/O4Zppg.jpg" alt=""></SwiperSlide>
          <SwiperSlide><div class="fs_24"> Slide 1</div></SwiperSlide>
          <SwiperSlide><div class="fs_24"> Slide 2</div></SwiperSlide> -->
        </Swiper>
      </div>
      <!-- <input type="text" class="input" placeholder="输入内容"> -->
      <div class="video">
        <div class="news-title">专注项目资金下发</div>
        <div class="news-title-desc">{{system_config.title}}用户内测特邀版</div>
        <video class="video" controls v-if="system_config.video" :src="baseUrl + system_config.video"></video>
      </div>
      <div class="desc">
        ●  拥有高效的实时结算与清算能力，确保呆滞项目资金全权处理，呆滞资金账户迅速复刻授权，极大地提升了资金流转效率，为用户带来便捷体验。<br />
        ●  运用先进技术，根据交易特性和网络状况挑选最优处理路径，保障处理的稳定性和可靠性，动态调整以适应不同场景需求。<br />
        ●  积极支持无感清算、智能识别等前沿处理方式，紧跟轮动项目板快环境，满足用户多样化项目资金下发需求。<br />
        
      </div>

      <div class="open">
        <div class="news-title">当前{{system_config.title}}正在使用的人数</div>
        <div class="news-title-desc">为用户提供了优质的项目资金下款体验</div>
        <div class="open-content center-between">
          <div class="open-content__scroll" @click="$router.push('/success')">
            <div class="animation">
              <div class="centery-between" v-for="it, i in fakeData" :key="i">
                <div class="fs_26 user">姓名：{{it.name}}</div>
                <div class="fs_26 user">UID：{{it.uid}}</div>
                <div class="fs_26 card">成功办卡</div>
                <!-- <div class="fs_26 time">{{ it.time_diff }}</div> -->
              </div>
              <div class="centery-between" v-for="it, i in fakeData" :key="i+66">
                <div class="fs_26 user">姓名：{{it.name}}</div>
                <div class="fs_26 user">UID：{{it.uid}}</div>
                <div class="fs_26 card">成功办卡</div>
                <!-- <div class="fs_26 time">{{ it.time_diff }}</div> -->
              </div>
            </div>
          </div>
          <div class="open-content__right centery">
            <div class="text">当前{{system_config.title}}使用人数</div>
            <div class="center-between num">
              <div class="num-box" v-for="it in system_config.test_quota?.length" :key="it+66">{{system_config.test_quota[it-1]}}</div>
            </div>
            <button class="btn" v-if="system_config.free_status" @click="$router.push('/free')">限时办理墨兰尊耀卡</button>
            <button class="btn" v-else @click="$router.push('/card')">办理墨兰尊耀卡</button>
            <!-- <button class="btn" v-else @click="goDownload">立即前往</button> -->
          </div>
        </div>
      </div>
      <div class="news-center" style="margin-top: 50px">
        <div class="news-title">{{system_config.title}}资讯中心</div>
        <div class="news-title-desc">丰富的信息宝库</div>
        <div class="flex">
          <div class="top-line flex">
            <div class="tabList centery">
              <div
                class="tab"
                v-for="typeItem,index in newTypeList"
                :key="index"
                @click="changeNewType(typeItem)"
                :class="typeItem.id == new_type ? 'active' : ''"
              >{{typeItem.name}}</div>
            </div>
            <div class="more" @click="goNewListPage(1)">查看更多  ></div>
          </div>
        </div>
        <div class="swiper_new flex">
          <Swiper
            :modules="modules"
            class="swiper _swiper"
            :pagination="{ clickable: true }"
            loop
            slideToClickedSlide
            @activeIndexChange="activeIndexChange"
            @slideChange="onSlideChange"
            ref="mySwiper2"
          >
            <SwiperSlide v-for="it in newList1" @click.native="goNewsPage(it)" :key="it.id + 'new_2'">
              <img :src="baseUrl + it.image" alt="">
              <div class="desc">
                <div class="time">{{it.created_at}}</div>
                <div class="title oneLine">{{it.title}}</div>
              </div>
            </SwiperSlide>
          </Swiper>
          <div class="new_list">
            <div class="new_item" v-for="item,index in newList2" @click="goNewsPage(item)" :key="index">
              <div class="time">{{item.created_at}}</div>
              <div class="title oneLine">{{item.title}}</div>
              <div class="desc oneLine">{{item.desc}}</div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="news">
        <div class="news-title">新闻中心</div>
        <div class="tabList center">
          <div
            v-for="typeItem in newTypeList"
            :key="typeItem.id"
            class="tab active"
            :class="new_type == typeItem.id ? 'active' : ''"
            @click="changeNewType(typeItem)"
            >
          {{typeItem.name}}</div>
          <div class="tab">银联公告</div>
        </div>
        <div class="swiperList flex centery-between">
          <div class="swiper_new flex">
            <Swiper
              :modules="modules"
              class="swiper _swiper"
              :pagination="{ clickable: true }"
              loop
              slideToClickedSlide
              @activeIndexChange="activeIndexChange"
              @slideChange="onSlideChange"
              ref="mySwiper2"
            >
              <SwiperSlide v-for="it in newList1" @click="goNewsPage(it)" :key="it.id + 'new_2'">
                <img :src="baseUrl + it.image" alt="">
                <div class="desc">
                  <div class="time">{{it.created_at}}</div>
                  <div class="title oneLine">{{it.title}}</div>
                </div>
              </SwiperSlide>
            </Swiper>
            <div class="new_list">
              <div class="new_item" v-for="item,index in newList2" :key="index">
                <div class="time">{{item.created_at}}</div>
                <div class="title oneLine">{{item.title}}</div>
              </div>
            </div>
          </div>
          <div class="swiper_new flex">
            <Swiper
              :modules="modules"
              class="swiper _swiper"
              :pagination="{ clickable: true }"
              loop
              slideToClickedSlide
              @activeIndexChange="activeIndexChange"
              @slideChange="onSlideChange"
              ref="mySwiper2"
            >
              <SwiperSlide v-for="it in newList1" @click="goNewsPage(it)" :key="it.id + 'new_2'">
                <img :src="baseUrl + it.image" alt="">
                <div class="desc">
                  <div class="time">{{it.created_at}}</div>
                  <div class="title oneLine">{{it.title}}</div>
                </div>
              </SwiperSlide>
            </Swiper>
            <div class="new_list">
              <div class="new_item" v-for="item,index in newList2" :key="index">
                <div class="time">{{item.created_at}}</div>
                <div class="title oneLine">{{item.title}}</div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- 最新资讯 -->
      <!-- <div class="news">
        <div class="news-title">最新资讯</div>
        <div class="swiper2">
          <Swiper
            :modules="modules"
            class="swiper _swiper"
            :pagination="{ clickable: true }"
            autoplay
            loop
            slideToClickedSlide
            @activeIndexChange="activeIndexChange"
            @slideChange="onSlideChange2"
            ref="mySwiper2"
          >
            <div class="sidebar centerx">
              <div
                class="sidebar-item"
                :class="{ 'sidebar-item-active': swiper2_active == i }"
                v-for="it, i in latestArticleList"
                :key="it.id + 'new_1'"
                @click="swiper2Change(i)"
              >
                {{ it.title }}
              </div>
            </div>
            <SwiperSlide v-for="it in latestArticleList" :key="it.id + 649">
              <img :src="baseUrl + it.image" alt="">
              <div class="desc">
                <div class="title">{{ it.title }}</div>
                <div class="sub">
                 {{it.desc}}
                </div>
                <div class="btn" @click="goNewsPage(it)">了解详情</div>
              </div>
            </SwiperSlide>
          </Swiper>

        </div>
      </div> -->
      <!-- 教学专区 -->
      <div class="news-center" style="margin-top: 50px">
        <div class="news-title">{{system_config.title}}视频专区</div>
        <div class="news-title-desc">为用户提供全面、详细的指导和培训</div>
        <div class="flex">
          <div class="top-line flex">
            <div class="tabList centery">
              <div
                class="tab"
                v-for="typeItem,index in teachTypeList"
                :key="index"
                @click="changeTeachType(typeItem)"
                :class="typeItem.id == teach_type ? 'active' : ''"
              >{{typeItem.name}}</div>
            </div>
            <div class="more" @click="goNewListPage(2)">查看更多  ></div>
          </div>
        </div>
        <div class="swiper_new flex">
          <Swiper
            :modules="modules"
            class="swiper _swiper"
            :pagination="{ clickable: true }"
            loop
            slideToClickedSlide
            @activeIndexChange="activeIndexChange"
            @slideChange="onSlideChange"
            ref="mySwiper2"
          >
            <SwiperSlide v-for="it in teachList" @click.native="goNewsPage(it)" :key="it.id">
              <img :src="baseUrl + it.image" alt="">
              <div class="desc">
                <div class="time">{{it.created_at}}</div>
                <div class="title oneLine">{{it.title}}</div>
              </div>
            </SwiperSlide>
          </Swiper>
          <div class="new_list">
            <div class="new_item" v-for="item,index in teachList" @click="goNewsPage(item)" :key="index">
              <div class="time">{{item.created_at}}</div>
              <div class="title oneLine">{{item.title}}</div>
              <div class="desc oneLine">{{item.desc}}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="teach">
        <div class="news-title">{{system_config.title}}视频专区</div>
        <div class="news-title-desc">为用户提供全面、详细的指导和培训</div>
        <div class="teach-content center">
          <div class="teach-content__item" v-for="item in teach[0]?.child" @click="goNewsPage(item)" :key="'teach_pc'+item.id">
            <div class="box">
              <div class="backimg" :style="{
                background: `url(${baseUrl+item.image}) no-repeat center center`,
                'background-size': '100% 100%',
              }">{{item.title}}</div>
              <div class="text">{{item.title}}</div>
            </div>
            <div class="title">{{item.title}}</div>
            <video controls :src="baseUrl+item.video"></video>
          </div>
          <div class="teach-content__item">
            <div class="box">
              <div class="backimg" :style="{
                background: `url(${'https://ooo.0x0.ooo/2024/09/22/O4vqXP.png'}) no-repeat center center`,
                'background-size': '100% 100%',
              }">充值教程</div>
              <div class="text">银联支付充值教程</div>
            </div>
          </div>
          <div class="teach-content__item">
            <div class="box">
              <div class="backimg" :style="{
                background: `url(${'https://ooo.0x0.ooo/2024/09/22/O4vqXP.png'}) no-repeat center center`,
                'background-size': '100% 100%',
              }">充值教程</div>
              <div class="text">银联支付充值教程</div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- 开通流程 -->
      <div class="step">
        <div class="step-title">开通流程</div>
        <div class="step-content centery">

          <div class="step-section">下载安装</div>
          <div class="step-single">
            <div>
              <img :src="baseUrl + system_config.image" class="logo" alt="">
              <div class="right">
                <img src="@/assets/image/download.png" class="icon" alt="">
                <div class="tip">点击下载{{system_config.title}}</div>
              </div>
            </div>
          </div>
          <img src="@/assets/image/next.png" class="step-next" alt="">

          <div class="step-section">{{m_step[0]}}</div>
          <div class="step-multiple center-between">
             <div class="step-multiple__item" :style="{ background: `url(${baseUrl + open_process[0]?.image}) no-repeat center`, 'background-size': `100%`,}">
              <!-- <img :src="baseUrl + open_process[0]?.image" class="example" alt="">
              <div class="right">
                <div class="num">1</div>
                <div class="text">{{open_process[0]?.title}}</div>
              </div> -->
            </div>
             <div class="step-multiple__item" :style="{ background: `url(${baseUrl + open_process[1]?.image}) no-repeat center`, 'background-size': `100%`,}">
              <!-- <img :src="baseUrl + open_process[1]?.image" class="example" alt="">
              <div class="right">
                <div class="num">2</div>
                <div class="text">{{open_process[1]?.title}}</div>
              </div> -->
            </div>
             <div class="step-multiple__item" :style="{ background: `url(${baseUrl + open_process[2]?.image}) no-repeat center`, 'background-size': `100%`,}">
              <!-- <img :src="baseUrl + open_process[2]?.image" class="example" alt="">
              <div class="right">
                <div class="num">3</div>
                <div class="text">{{open_process[2]?.title}}</div>
              </div> -->
            </div>
             <div class="step-multiple__item" :style="{ background: `url(${baseUrl + open_process[3]?.image}) no-repeat center`, 'background-size': `100%`,}">
              <!-- <img :src="baseUrl + open_process[3]?.image" class="example" alt="">
              <div class="right">
                <div class="num">4</div>
                <div class="text">{{open_process[3]?.title}}</div>
              </div> -->
            </div>
          </div>
          <img src="@/assets/image/next.png" class="step-next" alt="">

          <div class="step-section">{{m_step[1]}}</div>
          <div class="step-multiple center-between">
            <div class="step-multiple__item" :style="{ background: `url(${baseUrl + open_process[4]?.image}) no-repeat center`, 'background-size': `100%`,}">
              <!-- <img :src="baseUrl + open_process[4]?.image" class="example" alt="">
              <div class="right">
                <div class="num">5</div>
                <div class="text">{{open_process[4]?.title}}</div>
              </div> -->
            </div>
            <div class="step-multiple__item" :style="{ background: `url(${baseUrl + open_process[5]?.image}) no-repeat center`, 'background-size': `100%`,}">
              <!-- <img :src="baseUrl + open_process[5]?.image" class="example" alt="">
              <div class="right">
                <div class="num">6</div>
                <div class="text">{{open_process[5]?.title}}</div>
              </div> -->
            </div>
            <div class="step-multiple__item" :style="{ background: `url(${baseUrl + open_process[6]?.image}) no-repeat center`, 'background-size': `100%`,}">
              <!-- <img :src="baseUrl + open_process[6]?.image" class="example" alt="">
              <div class="right">
                <div class="num">7</div>
                <div class="text">{{open_process[6]?.title}}</div>
              </div> -->
            </div>
            <div class="step-multiple__item" :style="{ background: `url(${baseUrl + open_process[7]?.image}) no-repeat center`, 'background-size': `100%`,}">
              <!-- <img :src="baseUrl + open_process[7]?.image" class="example" alt="">
              <div class="right">
                <div class="num">8</div>
                <div class="text">{{open_process[7]?.title}}</div>
              </div> -->
            </div>
          </div>
          <img src="@/assets/image/next.png" class="step-next" alt="">

          <div class="step-section">{{m_step[2]}}</div>
          <div class="step-single">
            <div>
              <img :src="baseUrl + system_config.image" class="logo" alt="">
              <div class="right">
                <div style="font-weight:500;text-align:center;font-size:32rem">银联支付</div>
                <!-- <img src="@/assets/image/suc.png" class="icon2" alt=""> -->
                <div class="tip">赋能你的财富自由</div>
              </div>
            </div>
          </div>
          <!-- <img src="@/assets/image/next.png" class="step-next" alt=""> -->

        </div>
      </div>
    </div>
    <div class="device_mobile" :style="{ height: openMenu ? '0px' : 'auto', visibility: openMenu ? 'hidden': 'visible' }">
      <!-- 轮播图 -->
      <div class="swiper3">
        <Swiper
          :modules="modules"
          class="swiper"
          :pagination="{ clickable: true }"
          loop
          autoplay
          @slideChange="onSlideChange"
          ref="mySwiper3"
        >
          <SwiperSlide v-for="item in carousel" :key="item.id+9999">
            <img :src="baseUrl + item.images"  @click="handleSwiperClick(item)" alt="">
          </SwiperSlide>
        </Swiper>
      </div>
      <!-- <div class="wrapper">
        <img src="@/assets/image/home.png" class="home_img" alt="">
        <h3>{{system_config.title}}</h3>
        <span>银行业统一移动支付APP</span>
        <div class="button">
          <a href="javascript:;" target="_self">立即下载</a>
        </div>
        <p>银联支付APP是由各商业银行、产业各方与中国银联共建共享的移动支付战略产品</p>
      </div> -->
      <!-- 介绍视频 -->
      <div class="video">
        <h2>专注项目资金下发</h2>
        <div class="riskControlSection">{{system_config.title}}用户内测特邀版</div>
        <video width="100%" height="100%" preload="auto" v-if="system_config.video" :src="baseUrl + system_config.video" type="video/mp4" controls="">
        </video>
      </div>
      <div class="wrapper">
        <div class="video-text">
          <p>●  拥有高效的实时结算与清算能力，确保呆滞项目资金全权处理，呆滞资金账户迅速复刻授权，极大地提升了资金流转效率，为用户带来便捷体验。</p>
          <p>●  运用先进技术，根据交易特性和网络状况挑选最优处理路径，保障处理的稳定性和可靠性，动态调整以适应不同场景需求。</p>
          <p>●  积极支持无感清算、智能识别等前沿处理方式，紧跟轮动项目板快环境，满足用户多样化项目资金下发需求。</p>
        </div>
      </div>
      <!-- 预约模块 -->
        <h2>当前{{system_config.title}}正在使用的人数</h2>
        <div class="riskControlSection">为用户提供了优质的项目资金下款体验</div>
      <div class="open-content__scroll"  @click="$router.push('/success')">
        <div class="animation">
          <div class="centery-between" v-for="it, i in fakeData" :key="i">
            <div class="user">姓名：{{it.name}}</div>
            <div class="user">UID：{{it.uid}}</div>
            <div class="card">成功办卡</div>
            <!-- <div class="time">{{ it.time_diff }}</div> -->
          </div>
          <div class="centery-between" v-for="it, i in fakeData" :key="i+66">
            <div class="user">姓名：{{it.name}}</div>
            <div class="user">UID：：{{it.uid}}</div>
            <div class="card">成功办卡</div>
            <!-- <div class="time">{{ it.time_diff }}</div> -->
          </div>
        </div>
      </div>
      <div class="open-content__right centery">
        <div class="text">当前{{system_config.title}}使用人数</div>
        <div class="center-between num">
          <div class="num-box" v-for="it in system_config.test_quota?.length" :key="it+66">{{system_config.test_quota[it-1]}}</div>
        </div>
        <button class="btn" v-if="system_config.free_status" @click="$router.push('/free')">限时办理墨兰尊耀卡</button>
        <button class="btn" v-else @click="$router.push('/card')">办理墨兰尊耀卡</button>
        <!-- <button class="btn" v-else @click="goDownload">立即前往</button> -->
      </div>


      <div class="wrapper2" style="margin-top: 50px">
        <h2>{{system_config.title}}资讯中心</h2>
        <div class="riskControlSection">为用户提供了优质的项目资金下款体验</div>
        <div class="tabList center">
          <div
            class="tab"
            :class="typeItem.id == new_type ? 'active' : ''"
            v-for="typeItem,index in newTypeList"
            :key="index"
            @click="changeNewType(typeItem)">
            {{typeItem.name}}</div>
        </div>
      </div>
      <div class="swiper_new">
        <Swiper
          :modules="modules"
          class="swiper _swiper"
          :pagination="{ clickable: true }"
          loop
          slideToClickedSlide
          @activeIndexChange="activeIndexChange"
          @slideChange="onSlideChange"
          ref="mySwiper2"
        >
          <SwiperSlide v-for="it in newList1" :key="it.id + 'new_2'">
            <img @click="goNewsPage(it)" :src="baseUrl + it.image" alt="">
            <div class="desc">
              <div class="title oneLine">{{it.title}}</div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div class="new_list">
        <div class="new_item" v-for="item,index in newList1" @click="goNewsPage(item)" :key="index">
          <div class="time">{{item.created_at}}</div>
          <div class="title oneLine">{{item.title}}</div>
          <div class="desc twoLine">{{item.desc}}</div>
        </div>
      </div>
      <div class="more" @click="goNewListPage(1)">查看更多 ></div>
      <!-- <div class="wrapper2" style="margin-top: 80px">
        <h2>资讯</h2>
        <p class="riskControlSection">XXXXXXXX XXXX XXXX</p>
      </div>
      <div class="swiper4">
        <Swiper
          :modules="modules"
          class="swiper _swiper"
          :pagination="{ clickable: true }"
          loop
          slideToClickedSlide
          @activeIndexChange="activeIndexChange"
          @slideChange="onSlideChange"
          ref="mySwiper2"
        >
          <SwiperSlide v-for="it in latestArticleList" @click="goNewsPage(it)" :key="it.id + 'new_2'">
            <img :src="baseUrl + it.image" alt="">
            <div class="desc">
              <div class="title ">{{it.title}}</div>
              <div class="sub">
                {{it.desc}}
              </div>
              <div class="btn">了解详情</div>
            </div>
          </SwiperSlide>
        </Swiper>

      </div> -->
      <!-- <div class="wrapper2" style="margin-top: 50px">
        <h2>银行卡管理</h2>
        <p class="riskControlSection">银行卡管理一步到位</p>
      </div> -->
      <!-- <div class="bg-text">
        <div class="img">
          <img free="PIC042" src="@/assets/image/manage.png" alt="">
        </div>
        <div class="text">
          <p>绑定银联卡，享受消费提醒、交易查询、手工记账服务</p>
          <p>全面的银联银行优惠权益查询，实时更新省钱省心</p>
          <p>手机扫码支付，银联金融级安全保护，资金安全无忧</p>
          <p>信用卡还款、手机充值、公共缴费、转账收款一站搞定</p>
          <p>在线办卡，足不出户、快捷省时，免去线下办理烦恼</p>
          <p>理财信贷，投资理财、贷款申请一站式服务</p>
        </div>
      </div>
      <div class="public-horizontalList-1-bottom">
        <div class="ul">
          <div class="li" v-for="item in 4" :key="item">
            <img src="@/assets/image/ph1.png" alt="" class="li-top">
            <div class="li-bottom">
              信用卡还款
              <br>
              0手续费
            </div>
          </div>
          <div class="li" v-show="show_li_more">
            <img src="@/assets/image/ph1.png" alt="" class="li-top">
            <div class="li-bottom">
              信用卡还款
              <br>
              0手续费
            </div>
          </div>
        </div>
        <div class="btn-mobile-more" @click="show_li_more = !show_li_more">{{ show_li_more ? '收起' : '查看更多' }}</div>
      </div> -->
      <!-- <div class="wrapper2" style="margin-top: 50px">
        <h2>优惠与权益</h2>
        <p class="riskControlSection">实时查看周边优惠，一站式查询专属权益</p>
      </div>
      <div class="public-horizontalList-3-background">
          <ul>
              <li>
                  <div class="img"><img src="https://cn.unionpay.com/upowhtml/cn/templates/material/9ed2b7ea4873410186ae96112fccfc7d/1579504130683.png" alt=""></div>
                  <div class="public-horizontalList-3-background-section">优惠   随时随地</div>
              </li>
              <li class="line"></li>
              <li>
                  <div class="img"><img src="https://cn.unionpay.com/upowhtml/cn/templates/material/9ed2b7ea4873410186ae96112fccfc7d/1579504186538.png" alt=""></div>
                  <div class="public-horizontalList-3-background-section">权益   随行随心</div>
              </li>
          </ul>
      </div> -->
      <div class="wrapper2" style="margin-top: 50px">
        <h2>{{system_config.title}}视频专区</h2>
        <div class="riskControlSection">为用户提供全面、详细的指导和培训</div>
        <div class="tabList center">
          <div
            class="tab"
            :class="typeItem.id == teach_type ? 'active' : ''"
            v-for="typeItem,index in teachTypeList"
            :key="index"
            @click="changeTeachType(typeItem)">
            {{typeItem.name}}</div>
        </div>
      </div>
      <div class="swiper_new">
        <Swiper
          :modules="modules"
          class="swiper _swiper"
          :pagination="{ clickable: true }"
          loop
          slideToClickedSlide
          @activeIndexChange="activeIndexChange"
          @slideChange="onSlideChange"
          ref="mySwiper2"
        >
          <SwiperSlide v-for="it in teachList" :key="it.id + 'new_2'">
            <img @click="goNewsPage(it)" :src="baseUrl + it.image" alt="">
            <div class="desc">
              <div class="title oneLine">{{it.title}}</div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div class="new_list">
        <div class="new_item" v-for="item,index in teachList" @click="goNewsPage(item)" :key="index">
          <div class="time">{{item.created_at}}</div>
          <div class="title oneLine">{{item.title}}</div>
          <div class="desc twoLine">{{item.desc}}</div>
        </div>
      </div>
      <div class="more" @click="goNewListPage(2)">查看更多 ></div>

      <!-- <div class="wrapper2" style="margin-top: 50px">
        <h2>{{system_config.title}}视频专区</h2>
        <div class="riskControlSection">为用户提供全面、详细的指导和培训</div>
      </div>
      <div class="public-horizontalList-3-bottom">
        <div class="li" v-for="item in teach[0]?.child" @click="goNewsPage(item)" :key="'teach_m'+item.id">
          <video controls :src="baseUrl+item.video"></video>
          <div class="title">{{item.title}}</div>
        </div>
      </div> -->
      <!-- <div class="public-horizontalList-2-bottom">
        <div class="li" v-for="item in teach[0]?.child" :key="'teach_m'+item.id">
          <div
            class="public-horizontalList-2-bottom-top"
            :style="{
              background: `url(${baseUrl+item.image})no-repeat center`,
              'background-size': `100%`,
            }"
          >
            <h6>银联风险系统</h6>
            <p>第一重</p>
          </div>
          <div class="public-horizontalList-2-bottom-bottom">
            {{item.title}}
          </div>
        </div>
      </div> -->
      <!-- <div class="riskControlFooterBanner">
        <div class="mobileFooterBannerList">
          <ul>
            <li>
              <h5>精准推荐、即时更新，精彩优惠一网打尽</h5>
              <div>
                <p>美食购物、娱乐旅行、生活服务，缤纷优惠不落幕；支付立减、抽奖试用、邀请有奖，多种形式随心选。</p>
              </div>
            </li>
            <li>
              <h5>汇集百余高端权益，尊贵礼遇触手可及</h5>
              <div id="quickPass-rights-lastli">
                <p>商旅出行、健康保障、人文娱乐、至臻生活，衣食住行非凡体验；多重权益独到甄选，商务旅途轻松省心、优质生活无忧畅享。</p>
              </div>
              <a href="https://billcloud.unionpay.com/upwxs-rsad/pcAdRight/firstCategoryList" target="_self" class="btn-style btn-center btn-mobile">查看权益</a>
            </li>
          </ul>
        </div>
      </div> -->
      <div class="wrapper2" style="margin-top: 50px">
        <h2>开通流程</h2>
        <p class="riskControlSection">简单操作，立即体验银联支付APP</p>
      </div>
      <div href="" class="download_btn" @click="$router.push('/download')" target="_self">欢迎下载{{system_config.title}}APP</div>
      <img src="@/assets/image/next.png" alt="" class="icon_next">
      <div class="m-step">
        <div class="m-step__tip">
          <div
            class="step_detail"
            v-for="item, index in m_step"
            :key="index+877"
            @click="swiper5Change5(index)"
          >
            <div class="img" :class="{ active: swiper5_active == index }"></div>
            {{ item }}
          </div>
        </div>
        <div class="swiper5">
          <Swiper
            :modules="modules"
            class="swiper"
            :pagination="{ clickable: true }"
            @slideChange="onSlideChange5"
            ref="mySwiper5"
          >
            <SwiperSlide>
              <div class="mobile-launchingProcess-list">
                <div style="width: 50%" :style="{ background: `url(${baseUrl + open_process[0]?.image}) no-repeat center`, 'background-size': `98%`,}">
                  <!-- <div
                    class="img"
                    :style="{
                      background: `url(${baseUrl + open_process[0]?.image}) no-repeat center`,
                      'background-size': `100%`,
                    }"
                  >
                    <span>01</span>
                  </div>
                  <p>{{ open_process[0]?.title}}</p> -->
                </div>
                <div style="width: 50%" :style="{ background: `url(${baseUrl + open_process[1]?.image}) no-repeat center`, 'background-size': `98%`,}">
                  <!-- <div
                    class="img"
                    :style="{
                      background: `url(${baseUrl + open_process[1]?.image}) no-repeat center`,
                      'background-size': `100%`,
                    }"
                  >
                    <span>02</span>
                  </div>
                  <p>{{ open_process[1]?.title}}</p> -->
                </div>
                <div style="width: 50%" :style="{ background: `url(${baseUrl + open_process[2]?.image}) no-repeat center`, 'background-size': `98%`,}">
                  <!-- <div
                    class="img"
                    :style="{
                      background: `url(${baseUrl + open_process[2]?.image}) no-repeat center`,
                      'background-size': `100%`,
                    }"
                  >
                    <span>03</span>
                  </div>
                  <p>{{ open_process[2]?.title}}</p> -->
                </div>
                <div style="width: 50%" :style="{ background: `url(${baseUrl + open_process[3]?.image}) no-repeat center`, 'background-size': `98%`,}">
                  <!-- <div
                    class="img"
                    :style="{
                      background: `url(${baseUrl + open_process[3]?.image}) no-repeat center`,
                      'background-size': `100%`,
                    }"
                  >
                    <span>04</span>
                  </div>
                  <p>{{ open_process[3]?.title}}</p> -->
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="mobile-launchingProcess-list">
                <div style="width: 50%" :style="{ background: `url(${baseUrl + open_process[4]?.image}) no-repeat center`, 'background-size': `98%`,}">
                  <!-- <div
                    class="img"
                    :style="{
                      background: `url(${baseUrl + open_process[4]?.image}) no-repeat center`,
                      'background-size': `100%`,
                    }"
                  >
                    <span>05</span>
                  </div>
                  <p>{{ open_process[4]?.title}}</p> -->
                </div>
                <div style="width: 50%" :style="{ background: `url(${baseUrl + open_process[5]?.image}) no-repeat center`, 'background-size': `98%`,}">
                  <!-- <div
                    class="img"
                    :style="{
                      background: `url(${baseUrl + open_process[5]?.image}) no-repeat center`,
                      'background-size': `100%`,
                    }"
                  >
                    <span>06</span>
                  </div>
                  <p>{{ open_process[5]?.title}}</p> -->
                </div>
                <div style="width: 50%" :style="{ background: `url(${baseUrl + open_process[6]?.image}) no-repeat center`, 'background-size': `98%`,}">
                  <!-- <div
                    class="img"
                    :style="{
                      background: `url(${baseUrl + open_process[6]?.image}) no-repeat center`,
                      'background-size': `100%`,
                    }"
                  >
                    <span>07</span>
                  </div>
                  <p>{{ open_process[6]?.title}}</p> -->
                </div>
                <div style="width: 50%" :style="{ background: `url(${baseUrl + open_process[7]?.image}) no-repeat center`, 'background-size': `98%`,}">
                  <!-- <div
                    class="img"
                    :style="{
                      background: `url(${baseUrl + open_process[7]?.image}) no-repeat center`,
                      'background-size': `100%`,
                    }"
                  >
                    <span>08</span>
                  </div>
                  <p>{{ open_process[7]?.title}}</p> -->
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="mobile-launchingProcess-list-over">
                <img  :src="baseUrl + system_config.image" alt="" class="img1">
                <!-- <img src="@/assets/image/suc.png" alt="" class="img2"> -->
                <h2>银联支付</h2>
                <p class="p-active">赋能你的财富自由</p>
                <!-- <a href="javascript:;" class="more" target="_self">前往银联支付网站了解更多相关内容	</a> -->
              </div>
            </SwiperSlide>
            <!-- <SwiperSlide><div class="fs_24"> Slide 1</div></SwiperSlide> -->
            <!-- <SwiperSlide><div class="fs_24"> Slide 2</div></SwiperSlide> -->
          </Swiper>
        </div>
      </div>
    </div>
    <!-- 公告 -->
    <div class="announcement_container" v-if="showAnnouncement && announcement">
      <div class="announcement">
        <div class="title">{{system_config.title}}官方公告</div>
        <div class="content" v-html="announcement"></div>
        <div class="btn" @click="handleAnnouncement">我已阅读</div>
      </div>
    </div>
  </div>
</template>

<script>
import {Toast} from 'vant'
  // import axios from 'axios';
  // import Swiper core and required modules
  import { Pagination, Autoplay } from 'swiper/modules';
  // import { Pagination, Autoplay } from 'Swiper';
  // import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';


  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
  // Import Swiper styles
  import 'swiper/css';
  // import 'swiper/css/navigation';
  import 'swiper/css/pagination';
  import 'swiper/css/scrollbar';
  import { mapState,mapMutations } from "vuex"
  import {baseUrl} from '@/setting'
  export default {
    name:'Main',
    components: {
      Swiper,
      SwiperSlide,
    },
    data() {
      return {
        baseUrl,
        modules: [Pagination, Autoplay],
        // Navigation,
        // Pagination,
        // Scrollbar,
        // A11y,
        swiperOption: {
          loop: true, // 启用无限循环
          autoplay: {
            delay: 3000, // 设置自动播放的延迟时间
            disableOnInteraction: true // 设置在用户交互后是否停止自动播放
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true, // 允许点击分页器来切换slide
          },
          // 其他swiper选项...
        },
        swiper2: null,
        swiper2_active: 0,
        show_li_more: false,
        m_step: ['手机登入', '修改设置', '着陆成功', ],
        swiper5_active: 0,
        carousel: [],
        fakeData: [],
        timer:null,
        latestArticleList:[],
        announcement:'',
        showAnnouncement:true,
        newTypeList:[],
        new_type:'',
        newList1:[],
        newList2:[],
        teachTypeList:[],
        teach_type:'',
        teachList:[]
      };
    }, // data

    computed: {
      ...mapState("nav", ["openMenu","teach","open_process"]),
      ...mapState("config", ["system_config"]),
      // openProcess(){
      //   let arr = this.open_process.child
      //   console.log(111,this.open_process.child);
      //   return arr && arr.length ? arr.reverse() : []
      // }
    }, // computed
    beforeRouteEnter(to, from, next){
      next(vm => {
        vm.showAnnouncement = true
        console.log('enter',vm.showAnnouncement);
      })
    },
    async created() {
      this.getNav()
      this.getHomeCarousel();
      this.getFakeData();
      this.getConfig();
      this.getArticleList()
      this.timer = setInterval(async () => {
        // await this.refreashConfig()
        this.getConfig()
      }, 10000)
    }, // mounted

    beforeDestroy() {
      clearInterval(this.timer)
    },

    methods: {
      ...mapMutations('config', ["SET_SYSTEM_CONFIG"]),
      handleSwiperClick(item){
        switch (item.url) {
          case '/success':
            this.$router.push('/success')
          break;
          case '/download':
            this.$router.push('/download')
            break;
          case '/search':
            this.$router.push('/search')
            break;
          // 预约使用银联支付APP
          case '/book':
            this.$router.push('/book')
            break;
          // 关于零费用使用银联支付自主操作系统
          case '/zero':
            this.$router.push('/zero')
            break;
          // 银联支付自助卡申请
          case '/card':
            this.$router.push('/card')
            break;
          case '/free':
            this.$router.push('/free')
          break;
          // 消费返还协议申请
          case '/cancel':
            this.$router.push('/cancel')
            break;
          // 文章列表
          case '/newList':
            if(item.is_video === 'true'){
              this.$router.push("/newList?category="+item.id+'&type=1')
            }else {
              this.$router.push("/newList?category="+item.id)
            }
            break;
          // 文章
          case '/news':
            if(item.article_id){
              this.$router.push("/news?id="+item.article_id)
              this.close()
            }else {
              this.$toast('暂未开放')
            }
            break;
          default:
            break;
        }
      },
      goDownload(){
        this.$router.push("/download")
        // if(this.$validate.fIsMobile()){
        //   this.$router.push("/download")
        // }
      },
      goNewListPage(type){
        this.$router.push("/articleList?type="+type)
      },
      changeTeachType(item){
        this.teach_type = item.id
        this.getTeachList()
      },
      changeNewType(item){
        this.new_type = item.id
        this.getNewList()
      },
      handleAnnouncement(){
        this.showAnnouncement = false
        // sessionStorage.setItem('announcementFlag',1)
      },
      async getNav() {
        const { data: res } = await this.$axios.get('/getNav')
        // if(sessionStorage.getItem('announcementFlag') == 1){
        //   this.announcement = ''
        // }else {
        //   this.announcement = res.data.notice?.introduction
        // }
        this.announcement = res.data.notice?.introduction
        this.newTypeList = res.data.news?.child_nav
        if(this.newTypeList.length){
          this.new_type = this.newTypeList[0].id
          this.getNewList()
        }
        this.teachTypeList = res.data.teach[0]?.child_nav
        if(this.teachTypeList.length){
          this.teach_type = this.teachTypeList[0].id
          this.getTeachList()
        }
      },
      async getTeachList(){
        const { data: res } = await this.$axios.get('/getArticleList?page=1&limit=3&type=1&category='+this.teach_type)
        if(res.code === 0){
          this.teachList = res.data.list
        }
      },
      async getNewList(){
        const { data: res } = await this.$axios.get('/getArticleList?page=1&limit=6&category='+this.new_type)
        if(res.code === 0){
          this.newList1 = res.data.list.slice(0,3)
          this.newList2 = res.data.list.slice(-3)
        }
      },
      async getArticleList(){
        // category 1最新资讯
        const { data: res } = await this.$axios.get('/getArticleList?page=1&limit=10')
        console.log('getArticleList',res);
        if(res.code === 0){
          this.latestArticleList = res.data.list
        }else {
          Toast(res.msg)
        }
      },
      goNewsPage(item) {
        this.$router.push("/news?id="+item.id)
      },
      goBookPage() {
        this.$router.push({ path: "/book"})
      },
      async refreashConfig(){
        await this.$axios.post('/decreaseQuota',{})
      },
      async getConfig() {
        const { data: res } = await this.$axios.get('/getConfig')
        document.title = res.data.system_config.title
        // console.log(res.data.system_config.test_quota+'');
        let num = res.data.system_config.test_quota+''
        let str = ''
        if(num.length < 7){
          for(let i=0;i<7-num.length;i++){
            str = '0'+str
          }
        }
        res.data.system_config.test_quota = str + num
        this.SET_SYSTEM_CONFIG(res.data.system_config)
      },
      async getHomeCarousel() {
        const { data: res } = await this.$axios.get('/getHomeCarousel')
        this.carousel = res.data
      },
      async getFakeData() {
        const { data: res } = await this.$axios.get('/getFakeData')
        this.fakeData = res.data
      },
      onSwiper(swiper) {
        console.log(swiper);
      },
      onSlideChange() {
        // console.log('slide change', i, i.activeIndex);
      },
      onSlideChange2() {
        // console.log('slide change', i, i.activeIndex);
        const swiperBullets = document.querySelectorAll('.swiper2 .swiper-pagination-bullet')
        swiperBullets.forEach((bullet, index) => {
          if (bullet.className.includes('swiper-pagination-bullet-active')) {
            this.swiper2_active = index;
          }
        })
        // this.swiper2_active = i.activeIndex;
        // console.log(this.swiper2[0])
      },
      onSlideChange5() {
        const swiperBullets = document.querySelectorAll('.swiper5 .swiper-pagination-bullet')
        swiperBullets.forEach((bullet, index) => {
          if (bullet.className.includes('swiper-pagination-bullet-active')) {
            this.swiper5_active = index;
          }
        })
      },
      swiper2Change(index) {
        const swiperBullets = document.querySelectorAll('.swiper2 .swiper-pagination-bullet')
        swiperBullets[index].click()
      },
      swiper5Change5(index) {
        const swiperBullets = document.querySelectorAll('.swiper5 .swiper-pagination-bullet')
        swiperBullets[index].click()
      },
      activeIndexChange() {
      // activeIndexChange(payload) {
        // console.log(payload);
      },
    } // methods
  }
</script>

<style lang="scss" scoped>
  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
  }
  @keyframes scroll {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(-50%);
    }
  }
  .announcement_container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    background-color: rgba(#000,.4);
    .announcement {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      width: 90%;
      max-width: 700px;
      background-color: #fff;
      border-radius: 20px;
      font-size: 20px;
      overflow: hidden;
      .title {
        height: 50px;
        font-size: 28px;
        color: #E60013;
        text-align: center;
        line-height: 50px;
      }
      .content {
        padding: 10px;
        height: 70vh;
        max-height: 700px;
        font-size: 14px;
        overflow-y: auto;
      }
      .btn {
        height: 40px;
        text-align: center;
        line-height: 40px;
        color: #fff;
        background-color: #E60013;
      }
    }
  }
  .main_page {
    width: 1920rem;
    // height: 592rem;
    margin: 0 0 392rem;
    font-size: 24rem;

    .device_pc {
      width: 1920rem;
      .swiper1 {
        ::v-deep .swiper {
          width: 1920rem;
          height: 592rem;
          .swiper-slide {
            width: 1920rem !important;
            // margin: 0 calc((1920rem - 1300rem) / 2);
            // background-color: pink;

            img {
              width: 1920rem;
              height: 592rem;
            }
          }
          .swiper-pagination {
            display: flex;
            align-items: center;
            justify-content: center;
            bottom: 62rem;
            height: 15rem !important;
            .swiper-pagination-bullet {
              width: 15rem;
              height: 15rem;
              margin: 0 10rem;
              background: #fff;
              opacity: 1;
              &.swiper-pagination-bullet-active {
                background: #E60013;
              }
            }
          }
        }
      }

      .input {
        display: block;
        width: 1200rem;
        height: 80rem;
        margin: 80rem auto 100rem;
        background: url('@/assets/image/search.png') no-repeat center center;
        background-size: 100% 100%;
        text-align: center;
        color: #000000;
        font-size: 36rem;
        font-weight: 500;
        font-style: normal;
        font-family: PingFang SC, PingFang SC;
      }
      .video {
        overflow: hidden;
        width: 1015rem;
        height: auto;
        margin: 60rem auto 0;
        border-radius: 16rem;
        vertical-align: middle;
      }
      > .desc {
        width: 1298rem;
        margin: 45rem auto 95rem;
        line-height: 40rem;
        color: #000000;
        font-size: 22rem;
        font-weight: 500;
        font-family: PingFang SC, PingFang SC;
      }
      .news-center {
        margin: 70rem auto;
        width: 1298rem;
        // .news-title {
        //   height: 77rem;
        //   margin: 0 0 20rem 0;
        //   text-align: left;
        //   line-height: 77rem;
        //   color: #000000;
        //   font-size: 55rem;
        //   font-weight: 500;
        //   font-style: normal;
        //   font-family: PingFang SC, PingFang SC;
        // }
        .top-line {
          flex: 1;
          margin-top: 20rem;
          height: 77rem;
          font-size: 30rem;
          text-align: center;
          .tabList {
            height: 77rem;
            flex: 1;
            .tab {
              padding: 0 30rem;
              &:first-child{
                border-right: 1px solid #ccc;
              }
              &.active {
                color: #DD2C34;
              }
            }
          }
          .more {
            line-height: 77rem;
          }
        }
        .swiper_new {
          flex-shrink: 0;
          ::v-deep .swiper {
            position: relative;
            width: 400px;
            height: 350px;
            border-radius: 5px;
            border: 1px solid #ccc;

            .swiper-slide {
              width: 400px !important;
              padding: 10px;
              // background-color: pink;

              img {
                display: block;
                width: 378px;
                height: 220px;
              }
              .desc {
                position: relative;
                // width: 100vw;
                margin: 0 0 20px;
                // height: calc(948rem - 776rem);
                padding: 20px 5.3px 0;
                box-sizing: border-box;
              }
            }
            .swiper-pagination {
              display: flex;
              align-items: center;
              justify-content: center;
              bottom: 8px;
              height: 6px !important;
              .swiper-pagination-bullet {
                width: 6px;
                height: 6px;
                margin: 0 2px;
                // background: #fff;
                background: rgba(34,34,34,0.40);
                opacity: 1;
                border-radius: 3px;
                &.swiper-pagination-bullet-active {
                  width: 14px;
                  background: #E60013;
                }
              }
            }
          }
        }
        .new_list {
          width: calc(100% - 400px);
          display: block;
          padding-left: 30rem;
          height: 350px;
          .new_item {
            padding-top: 20rem;
            height: 116px;
            border-bottom: 1px solid #ccc;
            &:first-child {
              border-top: 1px solid #ccc;
            }
            
          }
        }
        .title {
          // width: 100%;
          max-width: 95%;
          margin-bottom: 10px;
          line-height: 28px;
          letter-spacing: 1px;
          color: #222222;
          font-size: 16px;
          font-weight: 500;
        }
        .desc {
          font-size: 16px;
        }
        .time {
          margin-bottom: 15px;
          font-size: 14px;
          color: #DF2D33;
        }
      }
      .open {
        // &-title {
        //   height: 77rem;
        //   margin: 0 auto 70rem;
        //   text-align: center;
        //   line-height: 77rem;
        //   color: #000000;
        //   font-size: 55rem;
        //   font-weight: 500;
        //   font-style: normal;
        //   font-family: PingFang SC, PingFang SC;
        // }
        &-content {
          width: 1298rem;
          margin: 30rem auto 100rem;
          // padding: 0 90rem 0 0;
          box-sizing: border-box;

          &__scroll {
            overflow: hidden;
            width: 763rem;
            height: 383rem;
            margin: 6rem 0 0;
            padding: 0 26rem;
            background: #FFFFFF;
            white-space: nowrap;
            border-radius: 8rem;
            border: 4rem solid #CA3E3C;
            box-sizing: border-box;

            .animation {
              animation: scroll 25s linear infinite;
            }

            .user {
              width: 218rem;
            }
            .card {
              padding: 2rem 20rem;
              // width: 212rem;
              // height: 47rem;
              margin: 5rem ;
              background: #CA3E3C;
              text-align: center;
              line-height: 47rem;
              color: #fff;
              border-radius: 24rem;
            }
            .time {
              width: 104rem;
              text-align: right;
            }
          }

          &__right {
            flex-direction: column;
            padding-left: 100rem;
            width: calc(1526rem - 763rem - 218rem);
            .logo {
              width: 582rem;
              height: 159rem;
              margin: 0 0 76rem;
            }
            .text {
              height: 63rem;
              margin: 0rem 0 66rem;
              text-align: center;
              line-height: 63rem;
              color: #000000;
              font-size: 45rem;
              font-weight: 500;
              font-style: normal;
              font-family: PingFang SC, PingFang SC;
              // text-transform: none;
            }
            .num {
              width: 100%;
              .num-box {
                width: 75rem;
                height: 75rem;
                background: #CA3E3C;
                text-align: center;
                line-height: 75rem;
                color: #FFFFFF;
                font-size: 45rem;
                font-weight: 500;
                font-family: PingFang SC, PingFang SC;
                border-radius: 8rem;
              }
            }
            .btn {
              cursor: pointer;
              width: 581rem;
              height: 119rem;
              margin: 66rem 0 0;
              background: #CA3E3C;
              text-align: center;
              line-height: 119rem;
              color: #FFFFFF;
              font-size: 60rem;
              font-weight: 500;
              font-family: PingFang SC, PingFang SC;
            }
          }
        }
      }
      .news-title-desc {
        height: 50px;
        font-size: 20rem;
        color: #646464;
        letter-spacing: 6px;
        text-align: center;
      }
      .news {
        &-title {
          height: 77rem;
          margin: 0 auto 10rem;
          text-align: center;
          line-height: 77rem;
          color: #000000;
          font-size: 55rem;
          font-weight: 500;
          font-style: normal;
          font-family: PingFang SC, PingFang SC;
        }
        .swiper2 {
          ::v-deep .swiper {
            position: relative;
            width: 1298rem;
            height: 948rem;

            .sidebar {
              flex-direction: column;
              position: absolute;
              right: 0;
              top: 0;
              width: 360rem;
              height: 776rem;
              background: rgba($color: #fff, $alpha: 0.4);
              z-index: 9;

              &-item {
                width: 360rem;
                height: 52rem;
                margin: 5rem 0;
                padding: 0 40rem 0 0;
                text-align: right;
                line-height: 52rem;
                // color: #5a5351;
                color: #202020;
                font-size: 26rem;
                font-weight: 500;
                font-family: PingFang SC, PingFang SC;
                box-sizing: border-box;
                cursor: pointer;

                &-active {
                  background: #CA3E3C;
                  color: #fff;
                  font-size: 30rem;
                }
              }
            }

            .swiper-slide {
              width: 1298rem !important;
              // background-color: pink;

              img {
                display: block;
                width: 1298rem;
                height: 776rem;
              }
              .desc {
                position: relative;
                width: 100%;
                height: calc(948rem - 776rem);
                padding: 40rem 0 0 114rem;
                box-sizing: border-box;

                .title {
                  height: 37rem;
                  margin: 0 0 20rem;
                  line-height: 37rem;
                  color: #000000;
                  font-size: 26rem;
                  font-weight: 500;
                  font-family: PingFang SC, PingFang SC;
                }
                .sub {
                  line-height: 40rem;
                  color: #7f7f7f;
                  font-size: 22rem;
                  font-weight: 500;
                  font-family: PingFang SC, PingFang SC;
                }
                .btn {
                  cursor: pointer;
                  position: absolute;
                  right: 127rem;
                  bottom: 6rem;
                  width: 166rem;
                  height: 52rem;
                  border: 2rem solid #CA3E3C;
                  text-align: center;
                  line-height: calc(52rem - 2rem);
                  color: #CA3E3C;
                  font-size: 22rem;
                  font-weight: 500;
                  font-family: PingFang SC, PingFang SC;
                  border-radius: 26rem;
                  box-sizing: border-box;
                }
              }
            }
            .swiper-pagination {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              bottom: 81rem;
              height: 10rem !important;
              padding: 0 calc(148rem - 6.5rem) 0 0;
              box-sizing: border-box;
              .swiper-pagination-bullet {
                width: 10rem;
                height: 10rem;
                margin: 0 6.5rem;
                background: #7F7F7F;
                opacity: 1;
                &.swiper-pagination-bullet-active {
                  width: 31rem;
                  background: #E60013;
                  border-radius: 5rem;
                }
              }
            }
          }
        }
      }
      .teach {
        &-title {
          height: 77rem;
          margin: 94rem auto 100rem;
          text-align: center;
          line-height: 77rem;
          color: #000000;
          font-size: 55rem;
          font-weight: 500;
          font-style: normal;
          font-family: PingFang SC, PingFang SC;
        }
        &-content {
          width: 1298rem;
          // height: 597rem;
          margin: 0 auto;

          &__item {
            // margin-right: 30px;
            width: 491.5rem;
            // height: 100%;
            // border-bottom: 8rem solid #CA3E3C !important;
            border: 1rem solid #ccc !important;
            box-sizing: border-box;
            video {
              display: block;
              margin: 0 auto;
              height: 597rem;
            }
            .title {
              text-align: center;
              line-height: 60px;
            }
            .box {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100%;
              height: calc(597rem - 8rem);
              border-left: 1rem solid #d6d6d6;
              border-right: 1rem solid #d6d6d6;
              .backimg {
                width: 491.5rem;
                height: 334rem;
                margin: 0 0 50rem;
                padding: 0 18rem 0 0;
                text-align: center;
                line-height: 295rem;
                color: #000000;
                font-size: 55rem;
                font-weight: 500;
                font-family: PingFang SC, PingFang SC;
                box-sizing: border-box;
              }
              .text {
                height: 49rem;
                line-height: 49rem;
                color: #000000;
                font-size: 35rem;
                font-weight: 500;
                font-family: PingFang SC, PingFang SC;
              }
            }
          }
        }
      }
      .step {
        &-title {
          height: 77rem;
          margin: 100rem auto 66rem;
          text-align: center;
          line-height: 77rem;
          color: #000000;
          font-size: 55rem;
          font-weight: 500;
          font-style: normal;
          font-family: PingFang SC, PingFang SC;
        }

        &-content {
          width: 100%;
          flex-direction: column;
        }

        &-section {
          height: 46rem;
          margin: 10rem auto 30rem;
          line-height: 46rem;
          color: #000000;
          font-size: 33rem;
          font-weight: 500;
          font-family: PingFang SC, PingFang SC;
        }

        &-single {
          position: relative;
          >div {
            display: flex;
            width: 750rem;
            height: 260rem;
            margin: 3rem auto 0;
            padding: 54rem 0 53rem 195rem;
            background: #FFFFFF;
            box-shadow: 0px 0px 4rem 1rem rgba(0,0,0,0.16);
            box-sizing: border-box;
          }
          z-index: 9;

          &::before {
            content: '';
            position: absolute;
            top: 80rem;
            left: 50%;
            transform: translateX(-50%);
            width: 1920rem;
            height: 100rem;
            background: #F2F2F2;
            z-index: -1;
          }

          .logo {
            width: 159.09rem;
            height: 153rem;
            margin: 0 35rem 0 0;
          }
          .right {
            height: 153rem;
            padding: 29rem 0 0;
            box-sizing: border-box;
            .icon {
              width: 54rem;
              height: 54rem;
              margin: 0 0 9rem;
            }
            .icon2 {
              width: 40rem;
              height: 40rem;
              margin: 4rem 0 15rem;
            }
            .tip {
              height: 33rem;
              line-height: 33rem;
              color: #000000;
              font-size: 24rem;
              font-weight: 500;
              font-family: PingFang SC, PingFang SC;
            }
          }
        }
        &-multiple {
          position: relative;
          width: 1526rem;
          height: 260rem;
          z-index: 9;

          &::before {
            content: '';
            position: absolute;
            top: 80rem;
            left: 50%;
            transform: translateX(-50%);
            width: 1920rem;
            height: 100rem;
            background: #F2F2F2;
            z-index: -1;
          }

          &__item {
            display: flex;
            width: 364rem;
            height: 100%;
            padding: 31rem 0 32rem 39rem;
            background: #FFFFFF;
            box-shadow: 0px 4rem 6rem 1rem rgba(0,0,0,0.16);
            box-sizing: border-box;

            .example {
              width: 100rem;
              height: 197rem;
              margin: 0 24rem 0 0;
            }
            .right {
              height: 197rem;
              padding: 9rem 0 0;
              box-sizing: border-box;

              .num {
                width: 50rem;
                height: 50rem;
                margin: 0 0 20rem 1rem;
                padding: 0 0 0 3rem;
                background: #FFFFFF;
                border: 3rem solid #F5F5F5;
                text-align: center;
                line-height: 44rem;
                color: #000000;
                font-size: 26rem;
                font-weight: 600;
                font-family: PingFang SC, PingFang SC;
                border-radius: 50%;
                box-sizing: border-box;
              }
              .text {
                height: 28rem;
                line-height: 40rem;
                color: #000000;
                font-size: 20rem;
                font-weight: 600;
                font-family: PingFang SC, PingFang SC;
              }
            }
          }

        }
        &-next {
          width: 39rem;
          height: 39rem;
          margin: 17rem auto 10rem;
        }
      }
    }

    .device_mobile {
      h2 {
        color: #222222;
        text-align: center;
        font-family: fangzheng-unionpay-sc;
        letter-spacing: 2px;
        font-size: 24px;
        line-height: 26px;
        margin-bottom: 12px;
      }
      .riskControlSection {
        height: 50px;
        font-size: 11px;
        color: #646464;
        letter-spacing: 6px;
        text-align: center;
        
      }
      .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 89.3vw;
        margin: 30px auto 40px;
        // padding: 30px 0 0;
        
        .home_img {
          width: 165px;
          height: 200px;
          margin: 30px auto 20px;
        }

        h3 {
          margin-bottom: 12px;
          line-height: 26px;
          text-align: center;
          letter-spacing: 2px;
          color: #222222;
          font-size: 24px;
          font-weight: 400;
          font-family: fangzheng-unionpay-sc;
        }
        span {
          display: block;
          text-align: center;
          line-height: 14px;
          letter-spacing: 6px;
          color: #222222;
          font-size: 11px;
          font-weight: 400;
        }
        .button {
          width: 140px;
          height: 40px;
          margin: 20px auto 30px;
          background: #DD2C34;
          border-radius: 20px;
          a {
            display: block;
            text-align: center;
            line-height: 40px;
            letter-spacing: 1px;
            color: #FFFFFF;
            font-size: 13px;
            font-weight: 400;
          }
        }
        p {
          font-size: 16px;
          color: #222222;
          letter-spacing: 1px;
          text-align: center;
          line-height: 28px;
          font-weight: bold;
        }
        .video-text p {
          margin-top: 10px;
          text-align: left;
          line-height: 28px;
          letter-spacing: 0.5px;
          color: #222222;
          font-size: 13px;
          font-weight: 400;
        }
      }

      .open-content__scroll {
        overflow: hidden;
        width: 92vw;
        height: 45vw;
        margin: 0 auto 8vw;
        padding: 0 3vw;
        background: #FFFFFF;
        white-space: nowrap;
        border-radius: 8px;
        border: 4px solid #CA3E3C;
        color: #000000;
        font-size: 3vw;
        font-weight: 500;
        font-style: normal;
        font-family: PingFang SC, PingFang SC;
        box-sizing: border-box;

        .animation {
          animation: scroll 25s linear infinite;
        }

        .user {
          width: 25vw;
        }
        .card {
          padding: 2px 10px;
          // width: 25vw;
          // height: 4.8vw;
          margin: 0.7vw ;
          background: #CA3E3C;
          text-align: center;
          line-height: 4.8vw;
          color: #fff;
          border-radius: 2.4vw;
        }
        .time {
          width: 14vw;
          text-align: right;
        }
      }

      .open-content__right {
        flex-direction: column;
        width: 92vw;
        margin: 8vw auto;
        .logo {
          width: 582rem;
          height: 159rem;
          margin: 0 0 76rem;
        }
        .text {
          height: 5.2vw;
          margin: 0rem 0 5.5vw;
          text-align: center;
          line-height: 5.2vw;
          color: #000000;
          font-size: 4.5vw;
          font-weight: 500;
          font-style: normal;
          font-family: PingFang SC, PingFang SC;
          // text-transform: none;
        }
        .num {
          width: 100%;
          .num-box {
            width: 202rem;
            height: 202rem;
            background: #CA3E3C;
            text-align: center;
            line-height: 202rem;
            color: #FFFFFF;
            font-size: 92rem;
            font-weight: 500;
            font-family: PingFang SC, PingFang SC;
            border-radius: 29rem;
          }
        }
        .btn {
          width: 1225rem;
          height: 252rem;
          margin: 115rem 0 0;
          background: #CA3E3C;
          text-align: center;
          line-height: 252rem;
          color: #FFFFFF;
          font-size: 134rem;
          font-weight: 500;
          font-family: PingFang SC, PingFang SC;
        }
      }

      .wrapper2 {
        width: 100vw;
        
        .tabList {
          height: 30px;
          font-size: 16px;
          color: #646464;
          text-align: center;
          .tab {
            padding: 0 11px;
            line-height: 14px;
            &:first-child {
              border-right: 1px solid #ccc;
            }
            &.active {
              color: #DD2C34;
            }
          }
        }
      }

      .video {
        width: 100vw;
        height: auto;
        margin: 20px 0;
        video {
          vertical-align: middle;
        }
      }
      .swiper3 {
        // margin-top: 30px;
        height: 150px;
        ::v-deep .swiper {
          width: 100vw;
          height: 150px;

          .swiper-slide {
            width: 100vw !important;
            // margin: 452.77px;
            // background-color: pink;
    
            img {
              width: 100vw;
              height: 150px;
              object-fit: cover;
            }
          }
          .swiper-pagination {
            display: flex;
            align-items: center;
            justify-content: center;
            bottom: 10px;
            height: 6px !important;
            .swiper-pagination-bullet {
              width: 6px;
              height: 6px;
              margin: 0 2px;
              // background: #fff;
              background: rgba(34,34,34,0.40);
              opacity: 1;
              border-radius: 3px;
              &.swiper-pagination-bullet-active {
                width: 14px;
                background: #E60013;
              }
            }
          }
        }
      }
      .swiper_new {
        ::v-deep .swiper {
          position: relative;
          width: 95vw;
          // height: 948rem;
          border-radius: 5px;
          border: 1px solid #ccc;

          .swiper-slide {
            width: 95vw !important;
            padding: 10px 12px 10px 8px;
            // background-color: pink;

            img {
              display: block;
              width: 90vw;
              height: 52vw;
            }
            .desc {
              position: relative;
              // width: 100vw;
              // margin: 0 0 30px;
              // height: calc(948rem - 776rem);
              padding: 10px 5px 0;
              box-sizing: border-box;

              .title {
                // width: 100%;
                max-width: 95%;
                // margin-bottom: 10px;
                line-height: 28px;
                letter-spacing: 1px;
                color: #222222;
                font-size: 18px;
                font-weight: 700;
              }
            }
          }
          .swiper-pagination {
            display: flex;
            align-items: center;
            justify-content: center;
            bottom: 70px;
            height: 6px !important;
            .swiper-pagination-bullet {
              width: 6px;
              height: 6px;
              margin: 0 2px;
              // background: #fff;
              background: rgba(34,34,34,0.40);
              opacity: 1;
              border-radius: 3px;
              &.swiper-pagination-bullet-active {
                width: 14px;
                background: #E60013;
              }
            }
          }
        }
      }
      .new_list {
        padding: 30px 20px 0;
        .new_item {
          padding: 10px 15px;
          // height: 116px;
          border-bottom: 1px solid #ccc;
          .time {
            margin-bottom: 1px;
            font-size: 13px;
            color: #DF2D33;
          }
          .title {
            // width: 100%;
            max-width: 95%;
            margin-bottom: 10px;
            line-height: 28px;
            letter-spacing: 1px;
            color: #222222;
            font-size: 16px;
            font-weight: 500;
          }
          .desc {
            max-width: 95%;
            line-height: 20px;
            letter-spacing: 1px;
            color: #222222;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
      .more {
        border: 1px solid #DD2C34;
        border-radius: 16px;
        font-size: 14px;
        color: #DD2C34;
        letter-spacing: 0.5px;
        text-align: center;
        line-height: 30px;
        width: 100px;
        margin: 25px auto 0;
        display: block;
      }
      
      .swiper4 {
        margin: 50px 0 0;
        ::v-deep .swiper {
          position: relative;
          width: 100vw;
          // height: 948rem;

          .swiper-slide {
            width: 100vw !important;
            // background-color: pink;

            img {
              display: block;
              width: 100vw;
              height: 52vw;
            }
            .desc {
              position: relative;
              width: 100vw;
              margin: 0 0 30px;
              // height: calc(948rem - 776rem);
              padding: 20px 5.3vw 0;
              box-sizing: border-box;

              .title {
                width: 100%;
                margin-bottom: 10px;
                line-height: 28px;
                letter-spacing: 1px;
                color: #222222;
                font-size: 16px;
                font-weight: 500;
              }
              .sub {
                display: block;
                width: 100%;
                line-height: 22px;
                letter-spacing: 0.5px;
                color: #222222;
                font-size: 13px;
                font-weight: 400;
              }
              .btn {
                // position: absolute;
                // right: 127rem;
                // bottom: 6rem;
                width: 120px;
                height: 30px;
                margin: 30px auto 1px;
                background: #FFFFFF;
                border: 1px solid #CA3E3C;
                text-align: center;
                line-height: calc(28px);
                color: #CA3E3C;
                font-size: 13px;
                font-weight: 400;
                font-family: PingFang SC, PingFang SC;
                border-radius: 15px;
                box-sizing: border-box;
              }
            }
          }
          .swiper-pagination {
            display: flex;
            align-items: center;
            justify-content: center;
            bottom: 0;
            height: 6px !important;
            .swiper-pagination-bullet {
              width: 6px;
              height: 6px;
              margin: 0 2px;
              // background: #fff;
              background: rgba(34,34,34,0.40);
              opacity: 1;
              border-radius: 3px;
              &.swiper-pagination-bullet-active {
                width: 14px;
                background: #E60013;
              }
            }
          }
        }
      }

      .bg-text {
        margin: 30px auto 14px;
        .img {
          width: 53vw;
          height: auto;
          margin: 0 auto 10px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .text p {
          font-size: 13px;
          color: #222222;
          letter-spacing: 0;
          line-height: 32px;
          font-weight: 400;
          padding: 0 5.3%;
          text-align: center;
        }
      }
      .public-horizontalList-1-bottom {
        width: 100vw;
        height: auto;
        margin-top: 0;
        padding-bottom: 30px;
        background-color: #f2f2f2;
        .ul {
          overflow: hidden;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          width: 89.3vw;
          margin: 0 auto;
          padding-top: 20px;
          padding-bottom: 20px;

          .li {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 49%;
            height: 154px;
            margin: 0 0 9px;
            padding: 20px 10px;
            background: #ffffff;
            border: 1px solid #E3E3E3;

            &:nth-child(2n) {
              margin: 0 0 0 calc(2%);
            }
            .li-top {
              width: 48px;
              height: 48px;
              margin: 0 auto;
            }
            .li-bottom {
              margin-top: 10px;
              text-align: center;
              line-height: 28px;
              letter-spacing: 1px;
              color: #222222;
              font-size: 16px;
              font-weight: bold;
            }
          }
        }
        .btn-mobile-more {
          display: block;
          width: 120px;
          height: 30px;
          margin: 0 auto;
          border: 1px solid #DD2C34;
          text-align: center;
          line-height: 28px;
          color: #DD2C34;
          font-size: 14px;
          letter-spacing: 0.44px;
          border-radius: 16px;
          cursor: pointer;
        }
      }
      .public-horizontalList-2-bottom {
        margin: 0;

        .li {
          width: 89.3vw;
          margin: 0 auto 20px;
          background: #FFFFFF;
          border: 1px solid #E1E1E1;
        }
        &-top {
          overflow: hidden;
          position: relative;
          width: 88%;
          height: 104px;
          margin: 20px auto;
          h6 {
            margin: 24px 0 10px;
            line-height: 26px;
            text-align: center;
            letter-spacing: 2px;
            color: #ffffff;
            font-size: 24px;
            font-family: fangzheng-unionpay-sc;
          }
          p {
            text-align: center;
            line-height: 20px;
            letter-spacing: 0.88px;
            color: #ffffff;
            font-size: 14px;
          }
        }
        &-bottom {
          width: 88%;
          min-height: 66px;
          margin: 0 auto 20px auto;
          padding: 0;
          text-align: left;
          line-height: 22px;
          letter-spacing: 0.5px;
          color: #222222;
          font-size: 13px;
        }
      }
      .public-horizontalList-3-bottom {
        margin: 0;

        .li {
          width: 89.3vw;
          margin: 0 auto 20px;
          background: #FFFFFF;
          border: 1px solid #E1E1E1;
          video {
            display: block;
            margin: 0 auto 20px;
            width: 100%;
          }
          .title {
            font-size: 16px;
            text-align: center;
            height: 30px;
          }
        }
      }
      .public-horizontalList-3-background {
        overflow: hidden;
        width: 100%;
        height: 400px;
        margin: 30px auto 0;
        background: url('https://cn.unionpay.com/upowhtml/cn/resources/images/quickPass/rights-background@3x.png') no-repeat center;
        background-size: 100%;
        ul {
          margin: 0;
          padding: 0;
          width: 100%;
          height: 100%;
        }
        li {
          width: 100%;
          // float: left;
          padding: 0;
          margin: 0 auto;
          &:first-child {
            margin: 15px auto 0 auto;
          }
          .img {
            width: 60px;
            height: 60px;
            margin: 30px auto 20px auto;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .line {
          width: 80px;
          height: 1px;
          background-color: #ffffff;
          opacity: 0.5;
          margin: 0 auto;
          // float: none;
          // clear: both;
        }
        &-section {
          margin-bottom: 30px;
          margin-top: 20px;
          font-size: 20px;
          color: #FFFFFF;
          letter-spacing: 2px;
          text-align: center;
          line-height: 24px;
          font-family: fangzheng-unionpay-sc;
        }
      }
      .riskControlFooterBanner {
        .mobileFooterBannerList {
          // position: static;
          // left: auto;
          // right: auto;
          overflow: hidden;
          width: 89.3%;
          height: auto;
          margin: 0 auto;
          margin-top: -50px;
          // top: auto;
          background: #ffffff;
          >ul {
            margin: 0;
            padding: 0;
            li {
              // position: static;
              // left: auto;
              overflow: hidden;
              width: 100%;
              height: auto;
              margin: 0 auto;
              padding: 30px 20px;
              background: #F2F2F2;
              margin-bottom: 20px;

              h5 {
                width: 100%;
                margin-bottom: 20px;
                line-height: 26px;
                letter-spacing: 1px;
                color: #222222;
                font-size: 20px;
              }
              div p {
                width: 100%;
                letter-spacing: 0.5px;
                line-height: 22px;
                color: #222222;
                font-size: 13px;
              }
              .btn-mobile {
                display: block;
                width: 120px;
                height: 30px;
                margin: 20px auto 0;
                border: 1px solid #DD2C34;
                text-align: center;
                line-height: 28px;
                color: #DD2C34;
                font-size: 14px;
                letter-spacing: 0.44px;
                border-radius: 16px;
                cursor: pointer;
              }
            }
          }
        }
      }
      .download_btn {
        display: block;
        width: 200px;
        height: 40px;
        margin: 30px auto 20px;
        background: #DD2C34;
        letter-spacing: 1px;
        text-align: center;
        line-height: 40px;
        color: #FFFFFF;
        font-size: 13px;
        border-radius: 20px;
      }
      .icon_next {
        display: block;
        width: 16px;
        height: 16px;
        margin: 0 auto 10px;
      }

      .m-step {
        width: 100vw;
        &__tip {
          position: relative;
          display: flex;
          justify-content: space-around;
          align-items: center;
          width: 100vw;
          height: 48px;
          padding: 0 13.3%;
          margin-bottom: 30px;
          z-index: 9;

          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 10px;
            width: 100%;
            height: 1px;
            background: #e1e1e1;
            z-index: 2;

          }

          .step_detail {
            position: relative;
            line-height: 22px;
            letter-spacing: 0.5px;
            color: #222222;
            font-size: 13px;
            z-index: 8;

            .img {
              width: 20px;
              height: 20px;
              margin: 0 auto;
              margin-bottom: 6px;
              background: url('https://cn.unionpay.com/upowhtml/cn/resources/images/quickPass/launchingProcess-17@3x.png') no-repeat center;
              background-size: 12px 12px;

              &.active {
                background: url('https://cn.unionpay.com/upowhtml/cn/resources/images/quickPass/launchingProcess-16@3x.png') no-repeat center;
                background-size: cover;
              }
            }
          }
        }

        .swiper5 {
          // margin-top: 30px;
          height: 434px;
          ::v-deep .swiper {
            width: 100vw;
            height: 434px;

            .swiper-slide {
              width: 100vw !important;
              height: 398px;
              // margin: 452.77px;
              // background-color: pink;
      
              // img {
              //   width: 100vw;
              //   height: 398px;
              // }
            }
            .swiper-pagination {
              display: flex;
              align-items: center;
              justify-content: center;
              bottom: 0;
              height: 6px !important;
              .swiper-pagination-bullet {
                width: 6px;
                height: 6px;
                margin: 0 2px;
                // background: #fff;
                background: rgba(34,34,34,0.40);
                opacity: 1;
                border-radius: 3px;
                &.swiper-pagination-bullet-active {
                  width: 14px;
                  background: #E60013;
                }
              }
            }
          }

          .mobile-launchingProcess-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100vw;
            height: 100%;
            .img {
              width: 100%;
              height: 130px;
              padding-top: 9px;
              // background: url('@/assets/image/step1_1.png') no-repeat center !important;
              background-size: 162px 100% !important;

              span {
                display: block;
                text-align: center;
                line-height: 24px;
                letter-spacing: 0.83px;
                color: #DF2D33;
                font-size: 20px;
                font-weight: bold;
              }
            }
            p {
              width: 160px;
              margin: 10px auto 15px auto;
              text-align: center;
              line-height: 22px;
              letter-spacing: 0.5px;
              color: #222222;
              font-size: 13px;
            }

            &-over {
              padding: 30px 0 0;
              img {
                display: block;
              }
              .img1 {
                display: block;
                width: 160px;
                height: 160px;
                margin: 0 auto 230rem;
              }
              .img2 {
                width: 40px;
                height: 40px;
                margin: 62px auto 0;
              }
              .p-active {
                margin: 20px 0 0;
                text-align: center;
                line-height: 18px;
                letter-spacing: 0.5px;
                color: #222222;
                font-size: 16px;
              }

              .more {
                display: block;
                margin-top: 30px;
                text-decoration: underline;
                text-align: center;
                line-height: 16px;
                letter-spacing: 0.5px;
                color: #DD2C34;
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }

</style>