<template>
  <div>
    <TopNav/>
    <div :style="{ height: openMenu ? '0px' : 'auto', visibility: openMenu ? 'hidden': 'visible' }">
      <router-view />
    </div>
    <Footer/>
  </div>
</template>

<script>
import { mapState } from "vuex"
import TopNav from './components/topNav.vue'
import Footer from './components/footer.vue'
import { mapMutations } from "vuex"
export default {
  name:"Layout",
  components:{
    TopNav,
    Footer
  },
   computed: {
      ...mapState("nav", ["openMenu"]),
    },
  async created() {
    this.getNav();
  },
  methods: {
    ...mapMutations('nav', ["SET_OPEN_MENU", "SET_NAV", "SET_OTHER", "SET_TEACH", "SET_open_process"]),
    async getNav() {
      const { data: res } = await this.$axios.get('/getNav')
      console.log('getNav', res)
      // res.data.nav?.forEach(item => {
      //   if (item.name == "新闻中心") {
      //     item.click = () => {
      //       this.$router.push({ path: '/news/39' })
      //       this.SET_OPEN_MENU(false)
      //     }
      //   } else if (item.name == "个人业务") {
      //     item.click = () => {
      //       this.$router.push({ path: '/book' })
      //       this.SET_OPEN_MENU(false)
      //     }
      //   } else {
      //     item.click = () => {}
      //   }
      // })
      this.SET_NAV(res.data.nav)
      this.SET_OTHER(res.data.other)
      this.SET_TEACH(res.data.teach)
      this.SET_open_process(res.data.open_process.child)
    },
   
  },
}
</script>

<style>

</style>