
const state={
  openMenu: false,
  nav: [],
  other: [],
  teach: [],
  open_process:[]
}
const mutations= {
  SET_OPEN_MENU(state, status) {
    state.openMenu = status;
  },
  SET_NAV(state, nav) {
    if(nav.length >2){
      let nav1 = nav.slice(0,nav.length - 2)
      let nav2 = nav.slice(-2)
      let nav3 = [
        ...nav1,
        {
          id:998,
          name:'搜索',
          child_nav:[
            {id: 999,name: "搜索",href:'/search'},
          ],
        },
        ...nav2
      ]
      state.nav = nav3;
    }else {
      state.nav = [
        ...nav,
        {
          id:998,
          name:'搜索',
          child_nav:[
            {id: 999,name: "搜索",href:'/search'},
          ],
        }
      ]
    }
  },
  SET_OTHER(state, other) {
    state.other = other;
  },
  SET_TEACH(state, teach) {
    state.teach = teach;
  },
  SET_open_process(state, open_process) {
    state.open_process = open_process;
  },
}
const actions={
}
const getters= {
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};