<template>
  <div>
    <div class="device_pc">
      <div class="nav">
        <div class="centery">
          <img class="nav_logo" @click="goIndex" :src="baseUrl + system_config.image" alt="">
          <!-- <img class="nav_logo" @click="goIndex" src="@/assets/image/logo.png" alt=""> -->
          <div class="nav_title" @click="goIndex">{{system_config.title}}</div>
          <img class="nav_ipv6" src="@/assets/image/ip6.png" alt="">
          <div class="nav_list centery">
            <div class="nav_list_item fs_24" v-for="item in nav" :key="item.id" :class="item.id == activeNav1 ? 'selected' : ''" @click="navClick(item)">{{ item.name }}</div>
            <!-- <img class="nav_search" src="@/assets/image/nav_search.png" alt=""> -->
          </div>
        </div>
        <div class="nav_list_1" v-show="showNav">
          <div class="line_1">
            <div class="center wrapper_nav">
              <div class="nav_item" v-for="navItem in navList1" :class="navItem.id == activeNav2 ? 'selected' : ''" @click="nav1Click(navItem)" :key="navItem.id">
                <!-- <img :src="navItem.logo ? (baseUrl + navItem.logo) : '@/assets/image/nav_logo.png'" alt=""> -->
                <p>{{navItem.name}}</p>
              </div>
            </div>
          </div>
          <!-- <div class="line_2" v-show="showNav2">
            <div class="centery wrapper_nav">
              <div class="nav_item_2" v-for="navItem in navList2" :key="navItem.id" @click="articleClick(navItem)">{{navItem.title}}</div>
            </div>
          </div> -->
          <div class="nav_mask" @click="maskClick"></div>
        </div>
      </div>
    </div>
    <div class="device_mobile">
      <div class="m-nav centery-between">
        <img src="@/assets/image/menu.png" v-if="!openMenu" @click="SET_OPEN_MENU(true)" class="btn-icon" alt="">
        <img src="@/assets/image/close.png" v-else @click="SET_OPEN_MENU(false)" class="btn-icon" alt="">
        <img src="@/assets/image/icon_search.png" class="btn-icon" alt="">
        <div class="logo" @click="goIndex">
          <img :src="baseUrl + system_config.image" alt="">
          <span>{{system_config.title}}</span>
        </div>
        <img src="@/assets/image/support.png" class="ip-icon" alt="">

      </div>
      <div class="m-nav-cover" v-show="openMenu" @click.self="SET_OPEN_MENU(false)">
        <div class="m-nav-list">
          <template >
            <Collapse class="Collapse" :border="false" v-model="activeName" accordion>
              <CollapseItem is-link v-for="item in nav" :key="'m_col'+item.id" :title="item.name" :name="item.id" :border="false">
                <div class="clause" style="background: #fff;" v-for="navItem in item.child_nav" :key="navItem.id" @click="nav1Click(navItem)">{{ navItem.name }}</div>
              </CollapseItem>
            </Collapse>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import { Collapse, CollapseItem } from 'vant';
// import 'vant/lib/index.css';
import {baseUrl} from '@/setting'
export default {
  components: {
    Collapse,
    CollapseItem,
  },
  computed: {
    ...mapState("nav", ["openMenu", "nav"]),
    ...mapState("config", ["system_config"]),
  },
  data(){
    return {
      activeName:'',
      m_select:'',
      baseUrl,
      showNav:false,
      showNav2:false,
      navList1:[],
      activeNav1:'',
      activeNav2:'',
      navList2:[],
      // nav: [
			// {
			// 	"id": 1,
			// 	"name": "个人中心",
			// 	"child": [
      //     {
      //       child:[
      //         {id: 90,title: "文案填写页面"}
      //       ],
      //       id: 40,
      //       image: "",
      //       title: "银联自主系统"
      //     },
      //     {
      //       child:[
      //         {id: 91,title: "自主卡介绍页面"}
      //       ],
      //       id: 41,
      //       image: "",
      //       title: "卡产品与作用"
      //     },
      //   ]
			// },
			// {
			// 	"id": 2,
			// 	"name": "商户业务",
			// 	"child": [
      //     {
      //       child:[
      //         {id: 92,title: "项目1"},
      //         {id: 93,title: "项目1"},
      //       ],
      //       id: 42,
      //       image: "",
      //       title: "呆滞项目"
      //     },
      //      {
      //       child:[
      //         {id: 94,title: "项目2"},
      //         {id: 95,title: "项目3"},
      //       ],
      //       id: 43,
      //       image: "",
      //       title: "进行项目"
      //     },
      //   ]
			// },
			// {
			// 	"id": 3,
			// 	"name": "机构业务",
			// 	"child": []
			// },
			// {
			// 	"id": 4,
			// 	"name": "新闻中心",
			// 	"child": []
			// },
			// {
			// 	"id": 5,
			// 	"name": "了解银联",
			// 	"child": []
			// }
      // ]
    }
  },
  created(){
    this.getConfig()
  },
  methods: {
    ...mapMutations('nav', ["SET_OPEN_MENU"]),
    ...mapMutations('config', ["SET_SYSTEM_CONFIG"]),
    async getConfig() {
      const { data: res } = await this.$axios.get('/getConfig')
      document.title = res.data.system_config.title
      // console.log(res.data.system_config.test_quota+'');
      let num = res.data.system_config.test_quota+''
      let str = ''
      if(num.length < 7){
        for(let i=0;i<7-num.length;i++){
          str = '0'+str
        }
      }
      res.data.system_config.test_quota = str + num
      this.SET_SYSTEM_CONFIG(res.data.system_config)
    },
    goIndex() {
      this.$router.push({ path: "/" })
      this.SET_OPEN_MENU(false)
    },
    nav1Click(item){
      // this.navList2 = [...item.child]
      // this.showNav2 = true
      // this.activeNav2 = item.id
      console.log(item);
      if(item.pid){
        this.$axios.post('/count_views',{pid:item.pid,category_id:item.id})
      }
      switch (item.href) {
        case '/success':
          this.$router.push('/success')
          this.close()
          break;
        case '/search':
          this.$router.push('/search')
          this.close()
          break;
        // 预约使用银联支付APP
        case '/book':
          this.$router.push('/book')
          this.close()
          break;
        // 关于零费用使用银联支付自主操作系统
        case '/zero':
          this.$router.push('/zero')
          this.close()
          break;
        // 银联支付自助卡申请
        case '/card':
          this.$router.push('/card')
          this.close()
          break;
        // 银联支付自助卡申请
        case '/free':
          this.$router.push('/free')
          this.close()
          break;
        // 消费返还协议申请
        case '/cancel':
          this.$router.push('/cancel')
          this.close()
          break;
        // 文章列表
        case '/newList':
          if(item.is_video === 'true'){
            this.$router.push("/newList?category="+item.id+'&type=1')
          }else {
            this.$router.push("/newList?category="+item.id)
          }
          this.close()
          break;
        // 文章
        case '/news':
          if(item.article.id){
            this.$router.push("/news?id="+item.article.id)
            this.close()
          }else {
            this.$toast('暂未开放')
          }
          break;
        default:
          this.$toast('暂未开放')
          break;
      }
    },
    close(){
      this.maskClick()
      this.SET_OPEN_MENU(false)
    },
    navClick(item){
      console.log(item);
      
      if(item.name == '搜索'){
        this.activeNav1 = item.id
        this.$router.push('/search')
        this.close()
        return
      }
      this.navList1 = [...item.child_nav]
      this.showNav = true
      this.showNav2 = false
      this.activeNav1 = item.id
      this.activeNav2 = ''
      document.body.style.width='100vw';
      document.body.style.height='100vh';
      document.body.style.overflow='hidden';
      // if(item.child?.length){
      //   this.navList1 = [...item.child]
      //   this.showNav = true
      //   document.body.style.width='100vw';
      //   document.body.style.height='100vh';
      //   document.body.style.overflow='hidden';
      // }
      // if(item.href){
      //   window.location.href = item.href
      // }
    },
    articleClick(item){
      this.maskClick()
      this.SET_OPEN_MENU(false)
      this.$router.push("/news?id="+item.id)
    },
    maskClick(){
      this.showNav = false
      this.showNav2 = false
      document.body.style.width='';
      document.body.style.height='';
      document.body.style.overflow='';
    }
  },
}
</script>


<style lang="scss">
* {
  box-sizing: border-box;
}
// 大屏
.device_mobile {
  display: none;
}
.device_pc {
  display: block;
}

.nav {
  position: relative;
  padding: 22rem 0 18rem 211rem;
  // z-index: 2000;
  .nav_logo {
    width: 70rem;
    padding-right: 15rem;
  }
  .nav_title {
    cursor: pointer;
    padding-right: 24rem;
    font-weight: bold;
    font-size: 24rem;
    color: #CA3E3C;
  }
  .nav_ipv6 {
    padding-right: 40rem;
    height: 35rem;
  }
  .norem_nav_height {
    height: 62rem;
  }
  .nav_list {
    flex: 1;
    padding-left: 34rem;
    height: 80rem;
    background: url(../../../assets/image/nav_bg.png) no-repeat;
    background-size: 100% 100%;
    .nav_list_item {
      position: relative;
      padding: 0 18rem;
      color: #fff;
      cursor: pointer;
      &.selected {
        font-weight: 700;
      }
      &.active {
        padding: 0 0 0 30rem;
        &::before {
          content: "";
          position: absolute;
          width: 1rem;
          height: 16rem;
          background-color: #ffffff;
          display: inline-block;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          margin-left: 10rem;
        }
      }
      &.last_item {
        padding-right: 203rem;
      }
    }
  }
  // .wrapper {
  //   position: relative;
  //   &::after {
  //     content: "";
  //     background-color: var(--themeColor);
  //     height: 62rem;
  //     width: 100vw;
  //     position: absolute;
  //     top: 0;
  //     left: 99%;
  //   }
  // }
  .nav_search {
    // position: absolute;
    // right: 30rem;
    // top: 50%;
    // transform: translateY(-50%);
    width: 30rem;
    height: 30rem;
  }
  .nav_list_1 {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100vw;
    z-index: 9998;
    .wrapper_nav{
      margin: 0 auto;
      width: 1400rem;
    }
    .line_1 {
      padding: 20rem 0;
      background-color: #f2f2f2;
    }
    .nav_item {
      margin-right: 20rem;
      cursor: pointer;
      line-height: 50rem;
      &.selected  p{
        color: #DD2C34;
      }
      img {
        display: block;
        margin: 10rem auto;
        width: 56rem;
        height: 56rem;
      }
      p {
        // color: #DD2C34;
        font-size: 20rem;
        font-weight: 500;
      }
    }
    .line_2 {
      padding: 20rem 0;
      background-color: #fff;
      .nav_item_2 {
        cursor: pointer;
        margin-right: 40rem;
        line-height: 40rem;
        font-size: 20rem;
        &:hover {
          color: #DD2C34;
        }
      }
    }
    .nav_mask {
      background-color: rgba(#000,.5);
      height: 100vh;
    }
  }
}

// 手机
.m-nav {
  width: 100vw;
  height: 57px;
  padding: 15px 20px;
  border-top: 1px solid #CA3E3C;
  box-shadow: 0px 10px 20px rgba(0,0,0,0.1);

  .btn-icon {
    width: 24px;
    height: 24px;
    margin-right: 15px;
  }
  .logo {
    cursor: pointer;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 26px;
    img {
      height: 26px;
      padding-right: 10px;
    }
    span {
      font-weight: bold;
      font-size: 14px;
      color: #CA3E3C;
    }
  }
  .ip-icon {
    width: 32px;
    height: auto;
    margin-left: 46px;
  }
}

.m-nav-cover {
  width: 100vw;
  height: 800px;
  background-color: #fff;

  .m-nav-list {
    .m-nav-list_item {
      display: flex;
      align-items: center;
      width: 100vw;
      height: 58px;
      padding: 0 0 0 24px;
      background: #F2F2F2;
      border-bottom: 1px solid #e1e1e1;
      color: #222222;
      font-size: 16px;
      &.selected {
        span {
          transform: rotate(90deg);
        }
      }
      span {
        width: 16px;
        height: 16px;
        margin-right: 11px;
        background: url('@/assets/image/right-icon.png') no-repeat center center;
        background-size: contain;
      }
    }
    .clause {
      display: flex;
      align-items: center;
      width: 100vw;
      height: 48px;
      padding: 0 16px 0 50px;
      background: #f2f2f2;
      color: #323233;
      font-size: 14px;
      border-bottom: 1px solid #e1e1e1;
      cursor: pointer;
      }
  }
}
.van-cell{
  display: flex;
  align-items: center;
  width: 100vw;
  height: 58px;
  padding: 0 24px;
  background: #F2F2F2;
  border-bottom: 1px solid #e1e1e1;
  color: #222222;
  font-size: 16px;
}
.van-collapse-item__title--expanded .van-cell__right-icon::before {
  transform:rotate(90deg) !important;
}
.van-collapse-item__title .van-cell__right-icon::before {
  transform:rotate(0deg);
}
.van-collapse-item__content {
  padding: 0;
}
</style>



<style lang="scss">
// 手机

</style>