export const validate =  {
  checkMobile:(str) => {
    const reg = /^1[3456789]\d{9}$/
    if(reg.test(str))return true
    return false
  },
  checkIdcard:(str) => {
    const reg = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[X])$)$/
    if(reg.test(str))return true
    return false
  },
  checkNumber8:(str) => {
    const reg = /^(\d{8})$/
    if(reg.test(str))return true
    return false
  },
  fIsMobile:()=>{
    return /Android|iPhone|iPad|iPod|BlackBerry|webOS|Windows Phone|SymbianOS|IEMobile|Opera Mini/i.test(navigator.userAgent);
}
}