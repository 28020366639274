<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <!-- 不需要缓存数据的页面 -->
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
#app {
  overflow: hidden;
  min-height: 100vh;
  background-color: #fff;
  color: #000;
  /* font-size: 16px; */
}
:root {
  --themeColor:#BF2828;
}
</style>
