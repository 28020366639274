import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './styles/common.scss'
import './styles/media.css'
import './utils/rem'
import {Toast} from 'vant'
import {validate} from '@/utils/validate'

// 引入vuex
import store from './store'
import axios from 'axios';
// import Mock from 'mockjs';
// import "@/mockjs/index";
// 将 axios 绑定到 Vue 原型上，方便全局使用

// 配置 axios 默认的根路径
import {baseUrl} from './setting'
axios.defaults.baseURL = baseUrl;
axios.defaults.timeout = 10000;
Vue.prototype.$axios = axios.create({
  baseURL: baseUrl,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': null,
    'X-Requested-With': null,
    'Access-Control-Allow-Origin': '*',
  }
});
Vue.config.productionTip = false
Vue.prototype.$toast = Toast
Vue.prototype.$validate = validate
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
