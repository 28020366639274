
const state={
  system_config: {},
}
const mutations= {
  SET_SYSTEM_CONFIG(state, system_config) {
    state.system_config = system_config;
  },
}
const actions={
}
const getters= {
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};