import Vue from 'vue';
import Vuex from 'vuex';
import nav from './modules/nav';
import config from './modules/config';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    nav,
    config,
  }
});